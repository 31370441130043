.node__root>foreignObject {
  fill: #5e9ad9;
  y: -100
}

.node__root>text {
  fill: #5e9ad9;
  y: 16;
  x: -33;
}

.textNode {
  fill: red
}

.node__branch>foreignObject {
  fill: yellow;
}

.node__leaf>foreignObject {
  fill: #e271ad;
  y: 25
}

/* .node__leaf > text{
    fill: #5e9ad9;
    y:16;
    x:-33;
  } */

.tree-card .card {
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  color: #fff;
  background-color: #000;
  overflow: hidden;


}

/* Additional demo display styles */
* {
  box-sizing: border-box;
}

.tree-card .card h3 {
  margin-top: 1rem;
  font-size: 1.25rem;
}

.tree-card .card a {
  color: inherit;
}

.tree-card {
  min-width: 25vh;
  z-index: 1;
}

.myLabelComponentInSvg {
  height: 70px;
  width: 180px;
  margin: 10px;
}

/* .treeUser.treeUser1,.treeUser2,.treeUser3,.treeUser4,.treeUser5 */
.treeUser,
.treeUser1,
.treeUser2,
.treeUser3,
.treeUser4,
.treeUser5 {
  border-radius: 50%;
  border: 2px solid black;
  width: 50px;
  height: 50px;
  padding: 5px;
  background: #C6FFDD;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f7797d, #FBD786, #C6FFDD);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f7797d, #FBD786, #C6FFDD);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.treeUser2 {
  background: #FC5C7D;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6A82FB, #FC5C7D);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #6A82FB, #FC5C7D);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.treeUser3 {
  background: #B2FEFA;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0ED2F7, #B2FEFA);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0ED2F7, #B2FEFA);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.treeUser4 {
  background: #00c3ff;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ffff1c, #00c3ff);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ffff1c, #00c3ff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.treeUser5 {
  background: #c21500;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ffc500, #c21500);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ffc500, #c21500);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.treeWrapper {
  width: 100%;
}

.h-40vh {
  height: 40vh;
}

.close-icon {
  cursor: pointer;
  font-size: 30px !important;
}

.h-100per {
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .rd3t-g {
    transform: scale(0.55);
    translate: 135px 120px;
  }
}

@media only screen and (max-width: 374px) {
  .rd3t-g {
    transform: scale(0.5);
    translate: 96px 120px;
  }
}