  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */

.img-box-sec .inner-img-sec {
    /* width: 100%; */
    /* max-width: 300px; */
    /* max-height: 300px; */
    /* margin: 0 auto; */
}

.img-box-sec {
    position: relative;
    max-width: 300px;
    max-height: 300px;
}

.com-position {
    position: absolute;
    /* font-size: 24px; */
    font-size: 17px;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
}

.one {
    left: 143px;
    top: 134px;
    margin :3px;
}

.two {
    left: 143px;
    top: 103px;
    color: #fff;
    margin :3px;
}

.three {
    left: 143px;
    top: 164px;
    color: #fff;
}

.four {
    left: 110px;
    top: 104px;
    /*transform: rotate(303deg); */
    color: #fff;
    margin :3px;
}

.five {
    left: 173px;
    top: 103px;
    /*transform: rotate(45deg); */
    color: #fff;
    margin :3px;
}

.six {
    left: 175px;
    top: 164px;
    /*transform: rotate(325deg); */
    color: #fff;
    margin :3px;
}

.seven {
    left: 111px;
    top: 167px;
    color: #fff;
    margin :3px;
    /*transform: rotate(30deg); */
}

.eight {
    left: 74px;
    top: 106px;
    margin :3px;
    /*transform: rotate(294deg); */
}

.nine {
    left: 115px;
    top: 66px;
    margin :3px;
    /*transform: rotate(341deg); */
}

.ten {
    left: 163px;
    top: 66px;
    margin :3px;
    /*transform: rotate(30deg); */
}

.eleven {
    left: 204px;
    top: 105px;
    margin :3px;
    /*transform: rotate(69deg); */
}

.twelve {
    left: 205px;
    top: 160px;
    margin :3px;
    /*transform: rotate(293deg); */
}

.thirteen {
    left: 162px;
    top: 201px;
    margin :3px;
    /*transform: rotate(342deg); */
}

.fourteen {
    left: 106px;
    top: 200px;
    margin :3px;
    /*transform: rotate(29deg); */
}

.fifteen {
    left: 69px;
    top: 162px;
    margin :3px;
    /*transform: rotate(68deg); */
}

.sixteen {
    left: 35px;
    top: 116px;
    color: #fff;
    margin :3px;
    /*transform: rotate(295deg); */
}

.seventeen {
    left: 51px;
    top: 77px;
    color: #fff;
    margin :3px;
    /*transform: rotate(
295deg
); */
}

.eighteen {
    left: 79px;
    top: 50px;
    color: #fff;
    margin :3px;
    /*transform: rotate(
335deg
); */
}

.nineteen {
    left: 117px;
    top: 35px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
345deg
 ); */
}

.twenty {
    left: 156px;
    top: 34px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
22deg
 ); */
}

.twenty-one {
    left: 194px;
    top: 50px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
31deg
 ); */
}

.twenty-two {
    left: 222px;
    top: 77px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
63deg
 ); */
}

.twenty-three {
    left: 236px;
    top: 114px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
70deg
 ); */
}

.twenty-four {
    left: 235px;
    top: 154px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
292deg
 ); */
}

.twenty-five {
    left: 220px;
    top: 190px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
297deg
 ); */
}

.twenty-six {
    left: 192px;
    top: 218px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
338deg
 ); */
}

.twenty-seven {
    left: 154px;
    top: 234px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
338deg
 ); */
}

.twenty-eight {
    left: 117px;
    top: 233px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
25deg
 ); */
}

.twenty-nine {
    left: 80px;
    top: 217px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
25deg
 ); */
}

.thirty {
    left: 51px;
    top: 188px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
65deg
 ); */
}

.thirty-one {
    left: 38px;
    top: 155px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
66deg
 ); */
}

.thirty-two {
    left: 9px;
    top: 124px;
    color: #fff;
    /*transform: rotate( 
290deg
 ); */
    font-size: 16px;
}

.thirty-three {
    left: 19px;
    top: 101px;
    color: #fff;
    /*transform: rotate( 
299deg
 ); */
    font-size: 16px;
}

.thirty-four {
    left: 27px;
    top: 80px;
    color: #fff;
    /*transform: rotate( 
296deg
 ); */
    font-size: 16px;
}

.thirty-five {
    left: 37px;
    top: 56px;
    color: #fff;
    /*transform: rotate( 
299deg
 ); */
    font-size: 16px;
}

.thirty-six {
    left: 58px;
    top: 37px;
    color: #fff;
    /*transform: rotate( 
338deg
 ); */
    font-size: 16px;
}

.thirty-seven {
    left: 81px;
    top: 27px;
    color: #fff;
    /*transform: rotate( 
338deg
 ); */
    font-size: 16px;
}

.thirty-eight {
    left: 102px;
    top: 18px;
    color: #fff;
    /*transform: rotate( 
338deg
 ); */
    font-size: 16px;
}

.thirty-nine {
    left: 127px;
    top: 10px;
    color: #fff;
    /*transform: rotate( 
346deg
 ); */
    font-size: 16px;
}

.forty {
    left: 154px;
    top: 8px;
    color: #fff;
    /*transform: rotate( 
19deg
 ); */
    font-size: 16px;
}

.forty-one {
    left: 178px;
    top: 18px;
    color: #fff;
    /*transform: rotate( 
19deg
 ); */
    font-size: 16px;
}

.forty-two {
    left: 199px;
    top: 27px;
    color: #fff;
    /*transform: rotate( 
19deg
 ); */
    font-size: 16px;
}

.forty-three {
    left: 223px;
    top: 37px;
    color: #fff;
    /*transform: rotate( 
27deg
 ); */
    font-size: 16px;
}

.forty-four {
    left: 243px;
    top: 56px;
    color: #fff;
    /*transform: rotate( 
61deg
 ); */
    font-size: 16px;
}

.forty-five {
    left: 252px;
    top: 79px;
    color: #fff;
    /*transform: rotate( 
61deg
 ); */
    font-size: 16px;
}

.forty-six {
    left: 261px;
    top: 101px;
    color: #fff;
    /*transform: rotate( 
61deg
 ); */
    font-size: 16px;
}

.forty-seven {
    left: 271px;
    top: 127px;
    color: #fff;
    /*transform: rotate( 
61deg
 ); */
    font-size: 16px;
}

.forty-eight {
    left: 269px;
    top: 152px;
    color: #fff;
    /*transform: rotate( 
292deg
 ); */
    font-size: 16px;
}

.forty-nine {
    left: 260px;
    top: 176px;
    color: #fff;
    /*transform: rotate( 
292deg
 ); */
    font-size: 16px;
}

.fifty {
    left: 251px;
    top: 197px;
    color: #fff;
    /*transform: rotate( 
292deg
 ); */
    font-size: 16px;
}

.fifty-one {
    left: 242px;
    top: 220px;
    color: #fff;
    /*transform: rotate( 
292deg
 ); */
    font-size: 16px;
}

.fifty-two {
    left: 223px;
    top: 241px;
    color: #fff;
    /*transform: rotate( 
332deg
 ); */
    font-size: 16px;
}

.fifty-three {
    left: 199px;
    top: 251px;
    color: #fff;
    /*transform: rotate( 
332deg
 ); */
    font-size: 16px;
}

.fifty-four {
    left: 177px;
    top: 259px;
    color: #fff;
    /*transform: rotate( 
338deg
 ); */
    font-size: 16px;
}

.fifty-five {
    left: 154px;
    top: 269px;
    color: #fff;
    /*transform: rotate( 
338deg
 ); */
    font-size: 16px;
}

.fifty-six {
    left: 127px;
    top: 268px;
    color: #fff;
    /*transform: rotate( 
21deg
 ); */
    font-size: 16px;
}

.fifty-seven {
    left: 103px;
    top: 259px;
    color: #fff;
    /*transform: rotate( 
21deg
 ); */
    font-size: 16px;
}

.fifty-eight {
    left: 82px;
    top: 249px;
    color: #fff;
    /*transform: rotate( 
21deg
 ); */
    font-size: 16px;
}

.fifty-nine {
    left: 59px;
    top: 240px;
    color: #fff;
    /*transform: rotate( 
31deg
 ); */
    font-size: 16px;
}

.sixty {
    left: 39px;
    top: 221px;
    color: #fff;
    /*transform: rotate( 
59deg
 ); */
    font-size: 16px;
}

.sixty-one {
    left: 29px;
    top: 198px;
    color: #fff;
    /*transform: rotate( 
70deg
 ); */
    font-size: 16px;
}

.sixty-two {
    left: 20px;
    top: 176px;
    color: #fff;
    /*transform: rotate( 
66deg
 ); */
    font-size: 16px;
}

.sixty-three {
    left: 9px;
    top: 152px;
    color: #fff;
    /*transform: rotate( 
70deg
 ); */
    font-size: 16px;
}


@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */

.img-box-sec .inner-img-sec {
    /* width: 100%; */
    /* max-width: 300px; */
    /* max-height: 300px; */
    /* margin: 0 auto; */
}

.img-box-sec {
    position: relative;
    max-width: 300px;
    max-height: 300px;
}

.diamond-text{
    /* padding: 2px; */
}

.bg-green{
    color: lawngreen !important;
}

.bg-green-{
    background: #FDFC47;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #24FE41, #FDFC47);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #24FE41, #FDFC47); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    clip-path: polygon(100% 30%, 100% 40%, 90% 100%, 0% 100%, 0 20%);
}

.col-one {
    left: 143px;
    top: 134px;
}

.col-two {
    left: 143px;
    top: 103px;
    color: #fff;
}

.col-three {
    left: 143px;
    top: 164px;
    color: #fff;
}

.col-four {
    /* transform: rotate(303deg); */
    clip-path: polygon(100% 0, 100% 20%, 90% 76%, 0% 96%, 10% 21%);
    padding: 0px 2px 0px 2px;
}

.col-five {
    /* transform: rotate(45deg); */
}

.col-six {
    /* transform: rotate(325deg); */
}

.col-seven {
    /* transform: rotate(30deg); */
}

.col-eight {
    transform: rotate(294deg);
}

.col-nine {
    transform: rotate(341deg);
}

.col-ten {
    transform: rotate(30deg);
}

.col-eleven {
    transform: rotate(69deg);
}

.col-twelve {
    transform: rotate(293deg);
}

.col-thirteen {
    transform: rotate(342deg);
}

.col-fourteen {
    transform: rotate(29deg);
}

.col-fifteen {
    transform: rotate(68deg);
}

.col-sixteen {
    transform: rotate(295deg);
}

.col-seventeen {
    transform: rotate(
295deg
);
}

.col-eighteen {
    transform: rotate(
335deg
);
}

.col-nineteen {
    transform: rotate( 
345deg
 );
}

.col-twenty {
    transform: rotate( 
22deg
 );
}

.col-twenty-one {
    transform: rotate( 
31deg
 );
}

.col-twenty-two {
    transform: rotate( 
63deg
 );
}

.col-twenty-three {
    transform: rotate( 
70deg
 );
}

.col-twenty-four {
    transform: rotate( 
292deg
 );
}

.col-twenty-five {
    transform: rotate( 
297deg
 );
}

.col-twenty-six {
    transform: rotate( 
338deg
 );
}

.col-twenty-seven {
    transform: rotate( 
338deg
 );
}

.col-twenty-eight {
    transform: rotate( 
25deg
 );
}

.col-twenty-nine {
    transform: rotate( 
25deg
 );
}

.col-thirty {
    transform: rotate( 
65deg
 );
}

.col-thirty-one {
    transform: rotate( 
66deg
 );
}

.col-thirty-two {
    transform: rotate( 
290deg
 );
    font-size: 16px;
}

.col-thirty-three {
    transform: rotate( 
299deg
 );
 
}

.col-thirty-four {
    transform: rotate( 
296deg
 );
}

.col-thirty-five {
    transform: rotate( 
299deg
 );
}

.col-thirty-six {
    transform: rotate( 
338deg
 );
}

.col-thirty-seven {
    transform: rotate( 
338deg
 );
}

.col-thirty-eight {
    transform: rotate( 
338deg
 );
}

.col-thirty-nine {
    transform: rotate( 
346deg
 );
}

.col-forty {
    transform: rotate( 
19deg
 );
}

.col-forty-one {
    transform: rotate( 
19deg
 );
}

.col-forty-two {
    transform: rotate( 
19deg
 );
}

.col-forty-three {
    transform: rotate( 
27deg
 );
}

.col-forty-four {
    transform: rotate( 
61deg
 );
}

.col-forty-five {
    transform: rotate( 
61deg
 );
}

.col-forty-six {
    transform: rotate( 
61deg
 );
}

.col-forty-seven {
    transform: rotate( 
61deg
 );
}

.col-forty-eight {
    transform: rotate( 
292deg
 );
}

.col-forty-nine {
    transform: rotate( 
292deg
 );
}

.col-fifty {
    transform: rotate( 
292deg
 );
}

.col-fifty-one {
    transform: rotate( 
292deg
 );
}

.col-fifty-two {
    transform: rotate( 
332deg
 );
}

.col-fifty-three {
    transform: rotate( 
332deg
 );
}

.col-fifty-four {
    transform: rotate( 
338deg
 );
}

.col-fifty-five {
    transform: rotate( 
338deg
 );
}

.col-fifty-six {
    transform: rotate( 
21deg
 );
}

.col-fifty-seven {
    transform: rotate( 
21deg
 );
}

.col-fifty-eight {
    transform: rotate( 
21deg
 );
}

.col-fifty-nine {
    transform: rotate( 
31deg
 );
}

.col-sixty {
    transform: rotate( 
59deg
 );
}

.col-sixty-one {
    transform: rotate( 
70deg
 );
}

.col-sixty-two {
    transform: rotate( 
66deg
 );
}

.col-sixty-three {
    transform: rotate( 70deg);
}
