@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#root {
  height: 100%;
}

/* font css start*/
.f-1 {
  font-size: 1px !important;
}

.f-2 {
  font-size: 2px !important;
}

.f-3 {
  font-size: 3px !important;
}

.f-4 {
  font-size: 4px !important;
}

.f-5 {
  font-size: 5px !important;
}

.f-6 {
  font-size: 6px !important;
}

.f-7 {
  font-size: 7px !important;
}

.f-8 {
  font-size: 8px !important;
}

.f-9 {
  font-size: 9px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-19 {
  font-size: 19px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.f-24 {
  font-size: 24px !important;
}

.f-50 {
  font-size: 50px !important;
}

.f-30 {
  font-size: 30px !important;
}

.main-advertisment-banner {
  height: 200px;
  width: auto;

}

/* font size css end */

.alertBox {
  display: flex;
  justify-content: center;
  padding: 1.25rem;
}

.link-btn {
  background-color: #17a2b8 !important;
}

.success-btn {
  background-color: #28a745 !important;
}

.resend-layout {
  height: 100%;
  flex-direction: column;
}

.resend-layout input {
  border: none;
  border-bottom: 1px solid #ced4da;
}

.resend-layout h4,
.resend-layout h5 {
  color: #888888;
}

.resend-layout .input-group-img {
  padding: 5px;
}

.error-text {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
}

.div-center {
  display: flex;
  justify-content: center;
  width: 100%;
  /* height: 70%; */
}

.treee {
  display: flex;
  height: 173%;
  justify-content: center;
  width: 169%;
}

.sponser-tree {
  display: flex;
  height: 185%;
  justify-content: center;
  width: 205%;
}

.div-center-align {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer-layout {
  /* position: absolute; */
  z-index: 999;
  background: white;
  padding: 28px;
  width: 100%;
}

.footer-section {
  flex-direction: column;
}

.display-div {
  display: none;
}

/* .scrollable-element {
  scrollbar-color:  rgb(0, 21, 40, .88) transparent;
} */
.select-layout__menu ::-webkit-scrollbar-thumb {
  background: rgb(0, 21, 40, 0.88);
  border-radius: 10px;
}

.select-layout__control {
  border: none !important;
  border-bottom: 1px solid #00000040 !important;
  border-radius: 0;
  padding-left: 0;
  /* font-size: 18px; */
  color: #0a0a0a;
  font-weight: 500;
  margin-top: 6px;
}

.select-layout__indicators {
  height: -moz-fit-content;
  height: fit-content;
}

.select-layout__menu {
  color: grey !important;
  padding: 5px;
}

.User-details-sec .top-use-details input::-webkit-input-placeholder {
  color: grey;
}

.overlay-pic {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 31%;
  right: 0;
  height: 100%;
  border-radius: 50% !important;
  width: 150px;
  opacity: 0;
  transition: 0.3s ease;
  background-color: transparent;
}

.image-container {
  position: relative;
  /* width: 100%; */
  /* height: 300px; */
}

.image-container .after {
  position: absolute;
  top: 5%;
  right: 0;
  display: none;
  color: #fff;
}

.image-container:hover .after {
  display: block;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
}

.image-container i {
  font-size: 25px;
  padding: 10px;
}

.refer-layout {
  box-shadow: 0px 8px #d4c1c154 !important;
  /* font-size: 1.1vw !important; */
  padding: 5px;
}

.header-link li {
  padding: 5px;
  width: max-content;
}

.side-nav .menu-text {
  white-space: pre-line;
}

.side-nav .menu-icon .menu-padding {
  padding: 8px;
}

.header-profile-icon {
  min-width: 100%;
  border-radius: 50%;
  min-height: 100%;
}

.pre-layout .select-layout__control {
  font-size: 12px;
  margin-top: 0px;
}

.font-error {
  color: #fd0808 !important;
  font-weight: 700;
}

.modal-btn {
  background-color: rgb(0, 21, 40, 0.88);
}

.btn-gray {
  background-color: #888888 !important;
}

.btn-gray {
  background-color: #888888 !important;
}

.btn-danger {
  background-color: #e82646 !important;
}

.font-gray {
  color: #888888;
}

.modal-input input {
  border: none;
  border-bottom: 1px solid #ced4da;
}

.admin-form .form-control:disabled {
  background-color: #f5f5f5;
}

.social-links-register div button {
  display: block14;
}

.check-sponsor input {
  height: 14px !important;
}

.founder-layout h2 {
  color: #0090ff;
}

.founder-layout span {
  padding: 5px;
}

.pre-contact input {
  height: 30px;
}

.user-count button {
  color: white;
  font-weight: 800;
  background-color: transparent;
  border-radius: 8px;
  font-size: 20px;
  margin: 12px;
  padding: 0px 12px;
}

.user-count i {
  font-size: 42px;
  color: white;
  vertical-align: middle;
}

.video-sec iframe {
  width: 100%;
  height: 310px;
  border-radius: 8px;
}

.User-details-sec .top-use-details input {
  font-size: 18px;
}

.account-box-right {
  flex-direction: column;
}

.table-responsive-xl th {
  color: #888;
  font-weight: 500;
}

.blue-layout {
  background-color: #0024ff !important;
}

.scroll-layout {
  overflow: auto;
}

.dashboard-wraper {
  /* height: 100%; */
  margin-bottom: 5%;
  padding-bottom: 25px;
}

.wallet-box {
  /* width: 200px; */
  height: 200px;
}

.shadow-wallet {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.delete-popup .modal-dialog {
  box-shadow: 15px 10px 15px 10px rgba(0, 0, 0, 0.2);
  top: 15%;
  left: 8px;
}

.menu-text-wallet {
  color: white;
}

.menu-text-wallet:hover {
  color: #0024ff;
}

/* 
#root::-webkit-scrollbar { 
  background-color: gray;
 }

#root::-webkit-scrollbar-thumb {
  background: rgb(0, 21, 40, 0.88);
  border-radius: 10px;
} */

.scroll-layout::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f5f5f5;
  border: 2px solid #f8f8f8;
}

.scroll-layout::-webkit-scrollbar {
  height: 7px;
  background-color: #f5f5f5;
}

.scroll-layout::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #cbc7c7a6;
}

.register-page-translate select::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f5f5f5;
  border: 2px solid #f8f8f8;
}

.register-page-translate select::-webkit-scrollbar {
  height: 7px;
  background-color: #f5f5f5;
}

.register-page-translate select::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: transparent;
  border-radius: 1px solid gray;
}

.copy-area button {
  background-color: transparent;
  border-radius: 30px;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.transaction-layout td {
  color: #888888;
}

.tooltip-pos {
  position: relative;
  display: inline-block;
}

.tooltip-pos .tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;

  /* Position the tooltip */
  position: absolute;
  /* z-index: 1; */
  z-index: 1000;
}

.tooltip-pos:hover .tooltiptext {
  visibility: visible;
}

.diamond-number {
  opacity: 25%;
}

/* 
.dashboard-content {
  height: 100%;
  min-height: -webkit-fill-available;
  height: fill-available;
  overflow: auto;
} */

.button-layout {
  border-radius: 20px;
  background-color: transparent;
}

/* .vh-100-layout{
  height:100%
} */

.btn-outline-danger {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
  background-color: transparent !important;
  border: 2px solid !important;
}

.btn-outline-danger :hover {
  color: white !important;
  font-weight: 500;
}

.btn-outline-success {
  color: #28a745 !important;
  border-color: #28a745 !important;
  background-color: transparent !important;
  border: 2px solid !important;
}

input[placeholder] {
  color: #9f9f9f !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #9f9f9f;
}

.login-alert {
  width: 50%;
  float: right;
}

.modal-dialog {
  margin-top: 10%;
}

@media only screen and (max-width: 767px) {
  .login-alert {
    width: 100%;
    float: none;
  }

  .alertBox {
    padding: 0.5rem;
  }

  .dashboard-content {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .register-alert {
    position: absolute !important;
    bottom: 0px !important;
    z-index: +1 !important;
    width: 100% !important;
  }
}

.owned-sec table {
  overflow: scroll;
}

.google-translate {
  display: inline-block;
  vertical-align: top;
  padding-top: 15px;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

#google_translate_element select {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  color: #a4a7aa;
  border-radius: 0.25rem;
  width: 60%;
}

.header-translate #google_translate_element select {
  width: 100%;
  margin-top: 5px;
}

.register-page-translate select {
  font-family: "FontAwesome", "icomoon";
  background-color: transparent !important;
  background-clip: padding-box !important;
  color: #fff !important;
  border-radius: 0.25rem !important;
  width: 100px;
}

.register-page-translate select option {
  background-color: #002f53 !important;
}

#google_translate_element select option {
  background-color: white;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.icon-social-share {
  color: white;
  border-radius: 50%;
  min-width: 50px;
  padding: 20px;
}

.icon-facebook {
  background-color: #3b5998;
}

.icon-whatsapp {
  background-color: #4fce5d;
}

.icon-share {
  color: grey;
  /* border: 1px solid grey; */
  border-radius: 50%;
  min-width: 40px;
  padding: 15px;
}

.ul-layout {
  color: #888888;
  list-style-position: inside;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {

  .icon-layout {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
  }

  .social-icons-sec {
    /* width: 20px !important; */
    margin: 5px;
  }
}

.register-dots .owl-dots {
  box-shadow: 1px 2px 0px 2px #014366;
}

.register-dots .owl-dot {
  background-color: transparent;
  border-radius: 50%;
  padding: 4px !important;
  border: 2px solid white !important;
  margin: 2px;
  height: 10px;
  width: 10px;
}

.register-nav .owl-nav .owl-prev:hover,
.owl-next:hover {
  background-color: transparent !important;
  border: 1px solid #fff !important;
}

.register-nav .owl-nav .owl-prev:hover,
.owl-next:hover {
  margin-right: 5px;
}

.register-nav .owl-nav .owl-prev:hover,
.owl-next:hover {
  margin-left: 5px;
}

#fileData {
  font-family: calibri;
  padding: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border: 1px dashed #bbb;
  text-align: center;
  cursor: pointer;
  color: #bbb;
}

.diamond-img-scale {
  width: 300px;
  height: 300px;
}

@media only screen and (max-width: 375px) {

  .navbar-brand {
    width: 125px;
  }
}

@media only screen and (max-width: 775px) {
  .dash-head-blk .input-group.rounded p {
    display: none !important;
  }

  .referal-btn {
    display: none !important;
  }
}

body {
  top: 0px !important;
}

.blue-text {
  color: #0042ff;
}

.faq-img-layout img {
  width: 500px !important;
}

.reply-bg {
  /* color: #5b9efe !important; */
  color: #041562 !important;
  border-radius: 5px;
  text-align: right;
  font-weight: bold;
}

.comment-bg {
  border-radius: 5px;
  color: #777070 !important;
  text-align: left;
  font-weight: bold;
}

.font-blue {
  color: #000957;
  font-weight: bold;
}

.purchase-color {
  background-color: rgb(6 247 2 / 37%);
}

.marginTop-15 {
  margin-top: 15px;
}

.inside-input i {
  margin-top: "5%" !important;
  position: "absolute" !important;
  top: "0px" !important;
  right: "20px" !important;
}

.w-70 {
  width: 70%;
}

.dj-lite-bg {
  /* background-color: #b2e2f21c; */
  background-color: #b2e2f238;
}

.dj-premier-bg {
  background-color: #05d40014;
}

.dj-superlite-bg{
  background-color: #f6eefb;
  border: none;
}

.bg-light-green {
  background-color: #28a74536 !important;
}

.bg-light-red {
  background-color: #dc354540 !important;
}

.plan-dropdown {
  width: 150px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.header-translate {
  text-align: right;
}



@media (max-width: 767px) {
  h6 {
    font-size: 0.75rem;
  }

  .header-translate {
    text-align: left;
  }
}

.h-20 {
  height: 20px;
}

.badge-wrapper {
  position: relative;
  font-size: 30px;
}

.badge {
  position: absolute;
  top: -2px;
  right: -10px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  font-size: 12px;
}

.notify-cursor {
  cursor: pointer;
}

.bg-unread-msg {
  background-color: #d3d3d361;
}

.unread-msg-display {
  display: none !important;
}

.div-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.register-frame iframe {
  display: block;
  background: #000;
  border: none;
  height: 200px;
  width: 100%;
}

.video-frame iframe {
  display: block;
  background: #000;
  border: none;
  height: 400px;
  width: 100%;
}
.msg-box {
  /* height: 70vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.errspan {
  float: right;
  margin-right: 6px;
  margin-top: -34px;
  position: relative;
  z-index: 2;
  color: #01253d;
  border: transparent;
}

.system-color {
  background-color: #065d89 !important;
}

.system-text-color {
  color: #01253d;
}

.user-chat-details img {
  width: 50px;
  height: 50px;
}

/* .user-chat-details{ 
  border-bottom: 2px solid #065d89;
} */

.reply-box {
  width: -moz-fit-content;
  width: fit-content;
  float: right;
}

.send-box {
  width: -moz-fit-content;
  width: fit-content;
  float: left;
}

.p-cust-7 {
  padding: 7px !important;
}

.sidebar-text {
  font-size: 18px;
  color: #fff;
  display: block;
  padding: 15px 22px 15px 40px;
  position: relative;
  text-align: left;
  transition: all 0.1s ease-in;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
}

.pre-layout {
  max-width: none !important;
}

.banners-text-overflow {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.banners-text-overflow-header {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.h-90vh {
  height: 90vh;
}

.h-60vh {
  height: 60vh;
}

.h-35vh {
  height: 35vh;
}

.w-100 {
  width: 100%;
}

.submit-card-layout {
  height: 80vh;
  width: 80vh;
}

.register-banner-layout {
  height: 100px;
  overflow: scroll;
}

.register-banner-layout::-webkit-scrollbar {
  background-color: transparent;
  width: 2px;
}

.promo-border-img {
  border: 1px solid rgb(219, 216, 216);
}

.promo-border {
  border: 1px solid rgb(219, 216, 216);
  height: 70vh;
  overflow: scroll;
}

.promo-border::-webkit-scrollbar {
  width: 7px;
}

.register-banner-ads {
  flex-wrap: wrap;
}

.border-light-secondary {
  /* border-bottom: 1px solid #888888; */
  box-shadow: 0px 2px 2px -1px white;
}

@media only screen and (max-width: 768px) {
  .register-banner-ads {
    justify-content: center;
  }
}

#promo-textarea[readonly] {
  background-color: transparent;
}

.ad-banner {
  object-fit: contain;
}

.template-card-layout small {
  color: black;
  font-weight: 600;
}

.fw-600 {
  font-weight: 900;
  font-size: 15px;
}

.no-hover-btn {
  border-radius: 30px;
  min-width: 140px;
  color: #ffffff;
  border: none;
  padding: 10px;
}

.div-with-flex-wrap {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
}

.levelButton {
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
  border-radius: 30px;
  min-width: 140px;
  color: #ffffff;
  border: none;
  padding: 10px;
  background-color: #01253d;
}

.levelButton:hover {
  background-color: #aacefb !important;
}

.levelSelected {
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
  transform: translateY(4px);
  background-color: #aacefb !important
}
.dj-superlite-bg input[type="search"]{
  border: none;
  min-height: 45px;
}
.overflow-x-auto{
  overflow-x: auto;
}
.downline-search button{
  margin-left: 3px;
}
@media only screen and (max-width: 767px) {
  .downline-search{
    flex-direction: column;
  }
  .downline-search button{
      margin-left: 0px;
      margin-top: 3px;
  }
}
  */

.img-box-sec .inner-img-sec {
    /* width: 100%; */
    /* max-width: 300px; */
    /* max-height: 300px; */
    /* margin: 0 auto; */
}

.img-box-sec {
    position: relative;
    max-width: 300px;
    max-height: 300px;
}

.com-position {
    position: absolute;
    /* font-size: 24px; */
    font-size: 17px;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
}

.one {
    left: 143px;
    top: 134px;
    margin :3px;
}

.two {
    left: 143px;
    top: 103px;
    color: #fff;
    margin :3px;
}

.three {
    left: 143px;
    top: 164px;
    color: #fff;
}

.four {
    left: 110px;
    top: 104px;
    /*transform: rotate(303deg); */
    color: #fff;
    margin :3px;
}

.five {
    left: 173px;
    top: 103px;
    /*transform: rotate(45deg); */
    color: #fff;
    margin :3px;
}

.six {
    left: 175px;
    top: 164px;
    /*transform: rotate(325deg); */
    color: #fff;
    margin :3px;
}

.seven {
    left: 111px;
    top: 167px;
    color: #fff;
    margin :3px;
    /*transform: rotate(30deg); */
}

.eight {
    left: 74px;
    top: 106px;
    margin :3px;
    /*transform: rotate(294deg); */
}

.nine {
    left: 115px;
    top: 66px;
    margin :3px;
    /*transform: rotate(341deg); */
}

.ten {
    left: 163px;
    top: 66px;
    margin :3px;
    /*transform: rotate(30deg); */
}

.eleven {
    left: 204px;
    top: 105px;
    margin :3px;
    /*transform: rotate(69deg); */
}

.twelve {
    left: 205px;
    top: 160px;
    margin :3px;
    /*transform: rotate(293deg); */
}

.thirteen {
    left: 162px;
    top: 201px;
    margin :3px;
    /*transform: rotate(342deg); */
}

.fourteen {
    left: 106px;
    top: 200px;
    margin :3px;
    /*transform: rotate(29deg); */
}

.fifteen {
    left: 69px;
    top: 162px;
    margin :3px;
    /*transform: rotate(68deg); */
}

.sixteen {
    left: 35px;
    top: 116px;
    color: #fff;
    margin :3px;
    /*transform: rotate(295deg); */
}

.seventeen {
    left: 51px;
    top: 77px;
    color: #fff;
    margin :3px;
    /*transform: rotate(
295deg
); */
}

.eighteen {
    left: 79px;
    top: 50px;
    color: #fff;
    margin :3px;
    /*transform: rotate(
335deg
); */
}

.nineteen {
    left: 117px;
    top: 35px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
345deg
 ); */
}

.twenty {
    left: 156px;
    top: 34px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
22deg
 ); */
}

.twenty-one {
    left: 194px;
    top: 50px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
31deg
 ); */
}

.twenty-two {
    left: 222px;
    top: 77px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
63deg
 ); */
}

.twenty-three {
    left: 236px;
    top: 114px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
70deg
 ); */
}

.twenty-four {
    left: 235px;
    top: 154px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
292deg
 ); */
}

.twenty-five {
    left: 220px;
    top: 190px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
297deg
 ); */
}

.twenty-six {
    left: 192px;
    top: 218px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
338deg
 ); */
}

.twenty-seven {
    left: 154px;
    top: 234px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
338deg
 ); */
}

.twenty-eight {
    left: 117px;
    top: 233px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
25deg
 ); */
}

.twenty-nine {
    left: 80px;
    top: 217px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
25deg
 ); */
}

.thirty {
    left: 51px;
    top: 188px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
65deg
 ); */
}

.thirty-one {
    left: 38px;
    top: 155px;
    color: #fff;
    margin :3px;
    /*transform: rotate( 
66deg
 ); */
}

.thirty-two {
    left: 9px;
    top: 124px;
    color: #fff;
    /*transform: rotate( 
290deg
 ); */
    font-size: 16px;
}

.thirty-three {
    left: 19px;
    top: 101px;
    color: #fff;
    /*transform: rotate( 
299deg
 ); */
    font-size: 16px;
}

.thirty-four {
    left: 27px;
    top: 80px;
    color: #fff;
    /*transform: rotate( 
296deg
 ); */
    font-size: 16px;
}

.thirty-five {
    left: 37px;
    top: 56px;
    color: #fff;
    /*transform: rotate( 
299deg
 ); */
    font-size: 16px;
}

.thirty-six {
    left: 58px;
    top: 37px;
    color: #fff;
    /*transform: rotate( 
338deg
 ); */
    font-size: 16px;
}

.thirty-seven {
    left: 81px;
    top: 27px;
    color: #fff;
    /*transform: rotate( 
338deg
 ); */
    font-size: 16px;
}

.thirty-eight {
    left: 102px;
    top: 18px;
    color: #fff;
    /*transform: rotate( 
338deg
 ); */
    font-size: 16px;
}

.thirty-nine {
    left: 127px;
    top: 10px;
    color: #fff;
    /*transform: rotate( 
346deg
 ); */
    font-size: 16px;
}

.forty {
    left: 154px;
    top: 8px;
    color: #fff;
    /*transform: rotate( 
19deg
 ); */
    font-size: 16px;
}

.forty-one {
    left: 178px;
    top: 18px;
    color: #fff;
    /*transform: rotate( 
19deg
 ); */
    font-size: 16px;
}

.forty-two {
    left: 199px;
    top: 27px;
    color: #fff;
    /*transform: rotate( 
19deg
 ); */
    font-size: 16px;
}

.forty-three {
    left: 223px;
    top: 37px;
    color: #fff;
    /*transform: rotate( 
27deg
 ); */
    font-size: 16px;
}

.forty-four {
    left: 243px;
    top: 56px;
    color: #fff;
    /*transform: rotate( 
61deg
 ); */
    font-size: 16px;
}

.forty-five {
    left: 252px;
    top: 79px;
    color: #fff;
    /*transform: rotate( 
61deg
 ); */
    font-size: 16px;
}

.forty-six {
    left: 261px;
    top: 101px;
    color: #fff;
    /*transform: rotate( 
61deg
 ); */
    font-size: 16px;
}

.forty-seven {
    left: 271px;
    top: 127px;
    color: #fff;
    /*transform: rotate( 
61deg
 ); */
    font-size: 16px;
}

.forty-eight {
    left: 269px;
    top: 152px;
    color: #fff;
    /*transform: rotate( 
292deg
 ); */
    font-size: 16px;
}

.forty-nine {
    left: 260px;
    top: 176px;
    color: #fff;
    /*transform: rotate( 
292deg
 ); */
    font-size: 16px;
}

.fifty {
    left: 251px;
    top: 197px;
    color: #fff;
    /*transform: rotate( 
292deg
 ); */
    font-size: 16px;
}

.fifty-one {
    left: 242px;
    top: 220px;
    color: #fff;
    /*transform: rotate( 
292deg
 ); */
    font-size: 16px;
}

.fifty-two {
    left: 223px;
    top: 241px;
    color: #fff;
    /*transform: rotate( 
332deg
 ); */
    font-size: 16px;
}

.fifty-three {
    left: 199px;
    top: 251px;
    color: #fff;
    /*transform: rotate( 
332deg
 ); */
    font-size: 16px;
}

.fifty-four {
    left: 177px;
    top: 259px;
    color: #fff;
    /*transform: rotate( 
338deg
 ); */
    font-size: 16px;
}

.fifty-five {
    left: 154px;
    top: 269px;
    color: #fff;
    /*transform: rotate( 
338deg
 ); */
    font-size: 16px;
}

.fifty-six {
    left: 127px;
    top: 268px;
    color: #fff;
    /*transform: rotate( 
21deg
 ); */
    font-size: 16px;
}

.fifty-seven {
    left: 103px;
    top: 259px;
    color: #fff;
    /*transform: rotate( 
21deg
 ); */
    font-size: 16px;
}

.fifty-eight {
    left: 82px;
    top: 249px;
    color: #fff;
    /*transform: rotate( 
21deg
 ); */
    font-size: 16px;
}

.fifty-nine {
    left: 59px;
    top: 240px;
    color: #fff;
    /*transform: rotate( 
31deg
 ); */
    font-size: 16px;
}

.sixty {
    left: 39px;
    top: 221px;
    color: #fff;
    /*transform: rotate( 
59deg
 ); */
    font-size: 16px;
}

.sixty-one {
    left: 29px;
    top: 198px;
    color: #fff;
    /*transform: rotate( 
70deg
 ); */
    font-size: 16px;
}

.sixty-two {
    left: 20px;
    top: 176px;
    color: #fff;
    /*transform: rotate( 
66deg
 ); */
    font-size: 16px;
}

.sixty-three {
    left: 9px;
    top: 152px;
    color: #fff;
    /*transform: rotate( 
70deg
 ); */
    font-size: 16px;
} */

.img-box-sec .inner-img-sec {
    /* width: 100%; */
    /* max-width: 300px; */
    /* max-height: 300px; */
    /* margin: 0 auto; */
}

.img-box-sec {
    position: relative;
    max-width: 300px;
    max-height: 300px;
}

.diamond-text{
    /* padding: 2px; */
}

.bg-green{
    color: lawngreen !important;
}

.bg-green-{
    background: #FDFC47;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #24FE41, #FDFC47); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    clip-path: polygon(100% 30%, 100% 40%, 90% 100%, 0% 100%, 0 20%);
}

.col-one {
    left: 143px;
    top: 134px;
}

.col-two {
    left: 143px;
    top: 103px;
    color: #fff;
}

.col-three {
    left: 143px;
    top: 164px;
    color: #fff;
}

.col-four {
    /* transform: rotate(303deg); */
    clip-path: polygon(100% 0, 100% 20%, 90% 76%, 0% 96%, 10% 21%);
    padding: 0px 2px 0px 2px;
}

.col-five {
    /* transform: rotate(45deg); */
}

.col-six {
    /* transform: rotate(325deg); */
}

.col-seven {
    /* transform: rotate(30deg); */
}

.col-eight {
    transform: rotate(294deg);
}

.col-nine {
    transform: rotate(341deg);
}

.col-ten {
    transform: rotate(30deg);
}

.col-eleven {
    transform: rotate(69deg);
}

.col-twelve {
    transform: rotate(293deg);
}

.col-thirteen {
    transform: rotate(342deg);
}

.col-fourteen {
    transform: rotate(29deg);
}

.col-fifteen {
    transform: rotate(68deg);
}

.col-sixteen {
    transform: rotate(295deg);
}

.col-seventeen {
    transform: rotate(
295deg
);
}

.col-eighteen {
    transform: rotate(
335deg
);
}

.col-nineteen {
    transform: rotate( 
345deg
 );
}

.col-twenty {
    transform: rotate( 
22deg
 );
}

.col-twenty-one {
    transform: rotate( 
31deg
 );
}

.col-twenty-two {
    transform: rotate( 
63deg
 );
}

.col-twenty-three {
    transform: rotate( 
70deg
 );
}

.col-twenty-four {
    transform: rotate( 
292deg
 );
}

.col-twenty-five {
    transform: rotate( 
297deg
 );
}

.col-twenty-six {
    transform: rotate( 
338deg
 );
}

.col-twenty-seven {
    transform: rotate( 
338deg
 );
}

.col-twenty-eight {
    transform: rotate( 
25deg
 );
}

.col-twenty-nine {
    transform: rotate( 
25deg
 );
}

.col-thirty {
    transform: rotate( 
65deg
 );
}

.col-thirty-one {
    transform: rotate( 
66deg
 );
}

.col-thirty-two {
    transform: rotate( 
290deg
 );
    font-size: 16px;
}

.col-thirty-three {
    transform: rotate( 
299deg
 );
 
}

.col-thirty-four {
    transform: rotate( 
296deg
 );
}

.col-thirty-five {
    transform: rotate( 
299deg
 );
}

.col-thirty-six {
    transform: rotate( 
338deg
 );
}

.col-thirty-seven {
    transform: rotate( 
338deg
 );
}

.col-thirty-eight {
    transform: rotate( 
338deg
 );
}

.col-thirty-nine {
    transform: rotate( 
346deg
 );
}

.col-forty {
    transform: rotate( 
19deg
 );
}

.col-forty-one {
    transform: rotate( 
19deg
 );
}

.col-forty-two {
    transform: rotate( 
19deg
 );
}

.col-forty-three {
    transform: rotate( 
27deg
 );
}

.col-forty-four {
    transform: rotate( 
61deg
 );
}

.col-forty-five {
    transform: rotate( 
61deg
 );
}

.col-forty-six {
    transform: rotate( 
61deg
 );
}

.col-forty-seven {
    transform: rotate( 
61deg
 );
}

.col-forty-eight {
    transform: rotate( 
292deg
 );
}

.col-forty-nine {
    transform: rotate( 
292deg
 );
}

.col-fifty {
    transform: rotate( 
292deg
 );
}

.col-fifty-one {
    transform: rotate( 
292deg
 );
}

.col-fifty-two {
    transform: rotate( 
332deg
 );
}

.col-fifty-three {
    transform: rotate( 
332deg
 );
}

.col-fifty-four {
    transform: rotate( 
338deg
 );
}

.col-fifty-five {
    transform: rotate( 
338deg
 );
}

.col-fifty-six {
    transform: rotate( 
21deg
 );
}

.col-fifty-seven {
    transform: rotate( 
21deg
 );
}

.col-fifty-eight {
    transform: rotate( 
21deg
 );
}

.col-fifty-nine {
    transform: rotate( 
31deg
 );
}

.col-sixty {
    transform: rotate( 
59deg
 );
}

.col-sixty-one {
    transform: rotate( 
70deg
 );
}

.col-sixty-two {
    transform: rotate( 
66deg
 );
}

.col-sixty-three {
    transform: rotate( 70deg);
}

.node__root>foreignObject {
  fill: #5e9ad9;
  y: -100
}

.node__root>text {
  fill: #5e9ad9;
  y: 16;
  x: -33;
}

.textNode {
  fill: red
}

.node__branch>foreignObject {
  fill: yellow;
}

.node__leaf>foreignObject {
  fill: #e271ad;
  y: 25
}

/* .node__leaf > text{
    fill: #5e9ad9;
    y:16;
    x:-33;
  } */

.tree-card .card {
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  color: #fff;
  background-color: #000;
  overflow: hidden;


}

/* Additional demo display styles */
* {
  box-sizing: border-box;
}

.tree-card .card h3 {
  margin-top: 1rem;
  font-size: 1.25rem;
}

.tree-card .card a {
  color: inherit;
}

.tree-card {
  min-width: 25vh;
  z-index: 1;
}

.myLabelComponentInSvg {
  height: 70px;
  width: 180px;
  margin: 10px;
}

/* .treeUser.treeUser1,.treeUser2,.treeUser3,.treeUser4,.treeUser5 */
.treeUser,
.treeUser1,
.treeUser2,
.treeUser3,
.treeUser4,
.treeUser5 {
  border-radius: 50%;
  border: 2px solid black;
  width: 50px;
  height: 50px;
  padding: 5px;
  background: #C6FFDD;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f7797d, #FBD786, #C6FFDD);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.treeUser2 {
  background: #FC5C7D;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #6A82FB, #FC5C7D);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.treeUser3 {
  background: #B2FEFA;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0ED2F7, #B2FEFA);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.treeUser4 {
  background: #00c3ff;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ffff1c, #00c3ff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.treeUser5 {
  background: #c21500;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ffc500, #c21500);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.treeWrapper {
  width: 100%;
}

.h-40vh {
  height: 40vh;
}

.close-icon {
  cursor: pointer;
  font-size: 30px !important;
}

.h-100per {
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .rd3t-g {
    transform: scale(0.55);
    translate: 135px 120px;
  }
}

@media only screen and (max-width: 374px) {
  .rd3t-g {
    transform: scale(0.5);
    translate: 96px 120px;
  }
}
